import {
  computePosition,
  type Placement,
  type Middleware,
  flip,
  shift,
  autoUpdate,
  type Strategy
} from '@floating-ui/dom'
import type { CSSProperties } from 'vue'

export const useFloating = (
  placement?: Placement,
  strategy: Strategy = 'fixed'
) => {
  // refs
  const reference = ref<HTMLElement>()
  const floating = ref<HTMLElement>()
  const cleanup = ref<() => void>()
  const middleware: Middleware[] = [flip(), shift()]
  const style = ref<CSSProperties>()

  // hooks
  onBeforeUnmount(() => cleanup.value?.())

  // watchers
  watchEffect(() => {
    if (!reference.value || !floating.value) {
      return cleanup.value?.()
    }
    cleanup.value = autoUpdate(reference.value, floating.value, async () => {
      if (!reference.value || !floating.value) {
        return
      }
      const result = await computePosition(reference.value, floating.value, {
        middleware,
        placement,
        strategy
      })
      const { x = 0, y = 0, strategy: position } = result
      style.value = { position, left: `${x}px`, top: `${y}px` }
    })
  })
  return { reference, floating, style }
}
